import type {MediaEdge} from '@shopify/hydrogen/storefront-api-types';
import {ATTR_LOADING_EAGER} from '~/lib/const';
import type {MediaImage} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';

/**
 * A client component that defines a media gallery for hosting images, 3D models, and videos of products
 */
export function ProductGallery({
  media,
  className,
}: {
  media: MediaEdge['node'][];
  className?: string;
}) {
  if (!media.length) {
    return null;
  }

  return (
    <div
      className={`w-6/12 sm-max:w-full sm-only:w-full sm:justify-center sm-only:mb-4 flex ${className}`}
    >
      <div className="thumbnail-container lg:block hidden mr-16">
        {media.map((med) => {
          return (
            <img
              className="mb-2"
              width={96}
              height={128}
              src={med.image!.url}
              key={med.id || med.image!.id}
              alt=""
            />
          );
        })}
      </div>
      <div>
        {media.map((med, i) => {
          let mediaProps: Record<string, any> = {};

          const data = {
            ...med,
            image: {
              // @ts-ignore
              ...med.image,
              altText: med.alt || 'Product image',
            },
          } as MediaImage;

          switch (med.mediaContentType) {
            case 'IMAGE':
              mediaProps = {
                width: 800,
                widths: [400, 800, 1200, 1600, 2000, 2400],
              };
              break;
            case 'VIDEO':
              mediaProps = {
                width: '100%',
                autoPlay: true,
                controls: false,
                muted: true,
                loop: true,
                preload: 'auto',
              };
              break;
            case 'EXTERNAL_VIDEO':
              mediaProps = {width: '100%'};
              break;
            case 'MODEL_3D':
              mediaProps = {
                width: '100%',
                interactionPromptThreshold: '0',
                ar: true,
                loading: ATTR_LOADING_EAGER,
                disableZoom: true,
              };
              break;
          }

          return (
            <div
              className={clsx({
                'mb-2': true,
                'sm-only:hidden': i > 0,
                'sm-max:hidden': i > 0,
              })}
              // @ts-ignore
              key={med.id || med.image.id}
            >
              {/* TODO: Replace with MediaFile when it's available */}
              {(med as MediaImage).image && (
                <img
                  src={data.image!.url}
                  alt={data.image!.altText!}
                  width={489}
                  height={652}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
